import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Textarea } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditWorker() {
  const [value, setValue] = useState({name:'', email:'', phone:'', address:''});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);
  const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/worker/${id}`,{
      method:"PUT",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });
  }

  useEffect(()=>{

    fetch(`${url}/worker/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setValue({name: data.data.name, email: data.data.email, phone: data.data.phone, address: data.data.address});
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
        }
    });

  },[id]);




  return (
    <Form1 title="Edit Staff">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" type="text" placeholder="Enter Staff name:" value={value.name} lavel="Staff name:" />
        <Input1 onChange={set} name="email" type="email" placeholder="Enter Staff email:" value={value.email} lavel="Staff email:" />
        <Input1 onChange={set} name="phone" type="number" placeholder="Enter Staff Phone:" value={value.phone} lavel="Staff phone:" />
        <Textarea onChange={set} name="address" placeholder="Enter Staff address:" value={value.address} lavel="Staff address:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}
export default EditWorker;