import { combineReducers } from 'redux';
import page from './page';
import limit from './limit';


const rootReducer = combineReducers({
    limit,
    page

});

export default rootReducer;