import React from 'react';
import { useEffect, useState } from 'react';
import {CartGreen, CartCyan, CartLime, CartTeal, CartYellow, CartOrange, CartPink, CartFuchsia, CartPurple, CartViolet, CartIndigo} from '../components/Cart';
import url from '../url';
function Home() {

  const [totalProfit, setTotalProfit] = useState();
  const [thisYearProfit, setThisYearProfit] = useState();
  const [thisMonthProfit, setThisMonthProfit] = useState();
  const [thisWeekProfit, setThisWeekProfit] = useState();
  const [toDaysProfit, setToDaysProfit] = useState();


  const [totalDue, setTotalDue] = useState();
  const [thisYearDue, setThisYearDue] = useState();
  const [thisMonthDue, setThisMonthDue] = useState();
  const [thisWeekDue, setThisWeekDue] = useState();
  const [toDaysDue, setToDaysDue] = useState();


  const [totalSale, setTotalSale] = useState();
  const [thisYearSale, setThisYearSale] = useState();
  const [thisMonthSale, setThisMonthSale] = useState();
  const [thisWeekSale, setThisWeekSale] = useState();
  const [toDaysSale, setToDaysSale] = useState();

  console.log('home')


  useEffect(()=>{

    //------------------------------------sale start-----------------------------------

    fetch(`${url}/report/sale/totalSale`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      const t = data.reduce((a, b) => a + b.total, 0);


      setTotalSale(t);
    });


    fetch(`${url}/report/sale/thisYearSale`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      const t = data.reduce((a, b) => a + b.total, 0);

      setThisYearSale(t);
    });

    
    fetch(`${url}/report/sale/thisMonthSale`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{


      const t = data.reduce((a, b) => a + b.total, 0);

      setThisMonthSale(t);
    });

    fetch(`${url}/report/sale/thisWeekSale`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      
      const t = data.reduce((a, b) => a + b.total, 0);

       setThisWeekSale(t);
    });

    
    fetch(`${url}/report/sale/toDaySale`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      
      const t = data.reduce((a, b) => a + b.total, 0);

      setToDaysSale(t);

    });


    //---------------------------------------------------------sale end------------------------------------------------


    //--------------------------------------------profit start--------------------------------------------------------
      fetch(`${url}/report/sale/totalProfit`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

        const t = data.reduce((a, b) => a + b.total, 0);
        setTotalProfit(t);
      });


      fetch(`${url}/report/sale/thisYearProfit`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

        const t = data.reduce((a, b) => a + b.total, 0);
  
        setThisYearProfit(t);
      });
  
      
      fetch(`${url}/report/sale/thisMonthProfit`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
  
  
        const t = data.reduce((a, b) => a + b.total, 0);
  
        setThisMonthProfit(t);
      });
  
      fetch(`${url}/report/sale/thisWeekProfit`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
  
        
        const t = data.reduce((a, b) => a + b.total, 0);
  
         setThisWeekProfit(t);
      });
  
      fetch(`${url}/report/sale/toDayProfit`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
  
        
        const t = data.reduce((a, b) => a + b.total, 0);
  
         setToDaysProfit(t);
      });



      //--------------------------------------------profit end--------------------------------------------------------


      //--------------------------------------------Due start--------------------------------------------------------

    fetch(`${url}/report/sale/totalDue`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      const t = data.reduce((a, b) => a + b.total, 0);

      setTotalDue(t);
    });

    
    fetch(`${url}/report/sale/thisYearDue`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      const t = data.reduce((a, b) => a + b.total, 0);

      setThisYearDue(t);
    });

    
    fetch(`${url}/report/sale/thisMonthDue`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{


      const t = data.reduce((a, b) => a + b.total, 0);

      setThisMonthDue(t);
    });

    fetch(`${url}/report/sale/thisWeekDue`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      
      const t = data.reduce((a, b) => a + b.total, 0);

       setThisWeekDue(t);
    });


    fetch(`${url}/report/sale/toDayDue`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      
      const t = data.reduce((a, b) => a + b.total, 0);

       setToDaysDue(t);
    });



    //--------------------------------------------Due end--------------------------------------------------------



  })



  return (
    <div className="w-full h-auto p-4 pb-20 flex gap-4 flex-wrap">
        <CartGreen title="Total Sale" value={totalSale} icon={<i className="fa-solid fa-cart-shopping"></i>} />
        <CartCyan title="This Year Sale" value={thisYearSale} icon={<i className="fa-solid fa-cart-shopping"></i>} />
        <CartCyan title="This Month Sale" value={thisMonthSale} icon={<i className="fa-solid fa-cart-shopping"></i>} />
        <CartLime title="This Week Sale" value={thisWeekSale} icon={<i className="fa-solid fa-cart-shopping"></i>} />
        <CartGreen title="Today Sale" value={toDaysSale} icon={<i className="fa-solid fa-cart-shopping"></i>} />

        <CartYellow title="Total Profit" value={totalProfit} icon={<i className="fa-solid fa-sack-dollar"></i>} />
        <CartOrange title="This Year Profit" value={thisYearProfit} icon={<i className="fa-solid fa-sack-dollar"></i>} />
        <CartPink title="This Month Profit" value={thisMonthProfit} icon={<i className="fa-solid fa-sack-dollar"></i>} />
        <CartFuchsia title="This Week Profit" value={thisWeekProfit} icon={<i className="fa-solid fa-sack-dollar"></i>} />
        <CartTeal title="Today Profit" value={toDaysProfit} icon={<i className="fa-solid fa-sack-dollar"></i>} />

        <CartPurple title="Total Due" value={totalDue} icon={<i className="fa-solid fa-hand-holding-dollar"></i>} />
        <CartViolet title="This Year Due" value={thisYearDue} icon={<i className="fa-solid fa-hand-holding-dollar"></i>} />
        <CartIndigo title="This Month Due" value={thisMonthDue} icon={<i className="fa-solid fa-hand-holding-dollar"></i>} />
        <CartOrange title="This Week Due" value={thisWeekDue} icon={<i className="fa-solid fa-hand-holding-dollar"></i>} />
        <CartCyan title="Today Due" value={toDaysDue} icon={<i className="fa-solid fa-hand-holding-dollar"></i>} />


        {/* <CartPurple title="Total Due" value={totalDue} icon={<i className="fa-solid fa-hand-holding-dollar"></i>} /> */}
        {/* <CartTeal title="This Month Sale" value='150' icon={<i className="fa-solid fa-cart-shopping"></i>} />
        <CartCyan title="Last Month Sale" value='150' icon={<i className="fa-solid fa-cart-shopping"></i>} />
        <CartLime title="This Year Sale" value='150' icon={<i className="fa-solid fa-cart-shopping"></i>} />
        <CartYellow title="Today Profit" value='150' icon={<i className="fa-solid fa-sack-dollar"></i>} />
        <CartOrange title="This Month Profit" value='150' icon={<i className="fa-solid fa-sack-dollar"></i>} />
        <CartPink title="Last Month Profit" value='150' icon={<i className="fa-solid fa-sack-dollar"></i>} />
        <CartFuchsia title="This Year Profit" value='150' icon={<i className="fa-solid fa-sack-dollar"></i>} />
        <CartPurple title="Today Due" value='150' icon={<i className="fa-solid fa-hand-holding-dollar"></i>} />
        <CartViolet title="This Month Due" value='150' icon={<i className="fa-solid fa-hand-holding-dollar"></i>} />
        <CartIndigo title="Last Month Due" value='150' icon={<i className="fa-solid fa-hand-holding-dollar"></i>} />
        <CartOrange title="This Year Due" value='150' icon={<i className="fa-solid fa-hand-holding-dollar"></i>} />
        <CartGreen title="Today Expense" value='150' icon={<i className="fa-solid fa-building-columns"></i>} />
        <CartTeal title="This Month Expense" value='150' icon={<i className="fa-solid fa-building-columns"></i>} />
        <CartCyan title="Last Month Expense" value='150' icon={<i className="fa-solid fa-building-columns"></i>} />
        <CartLime title="This Year Expense" value='150' icon={<i className="fa-solid fa-building-columns"></i>} /> */}

    </div>
  )
}

export default Home;