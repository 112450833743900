import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Edit, View } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AllWorker() {

const [worker, setWorker] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);
const [alert, setAlert] = useState([]);





function delet(id){
    fetch(`${url}/worker/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/worker?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setWorker(data.result)
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/supplier/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setWorker(data.result)
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                    }
                });
                 
            }
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });
}
useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/worker?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){;
                setWorker(data.result);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            }
        });
    }

    return () => setWorker({data:[],next:{},previous:{}});

},[page, src, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/worker/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setWorker(data.result);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            }
        });
    }

    return () => setWorker({data:[],next:{},previous:{}});
},[page, limit, src]);

function search(e){
    setSrc(e.target.value);
}


  return (<>{
      loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
      <Table to="/addWorker" name="Add Staff" rowNames={["#","Name","Email","Phone No.","Address","Work Todo","Work Report","Actions"]} srcVal={src} srcFunc={search} data={worker} width='w-max'>
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
      {
          worker.data.map(({_id, name, email, phone, address}, index)=>{
              return(
                  <Tr key={index}>
                      <Td>{((worker.previous.page*worker.previous.limit)+1)+index}</Td>
                      <Td>{name}</Td>
                      <Td>{email}</Td>
                      <Td>{phone}</Td>
                      <Td>{address}</Td>
                      <Td><View to={'/workTodo/'+_id} /></Td>
                      <Td><View to={'/worker/'+_id} /></Td>
                      <Td>
                          <Edit to={"/editWorker/"+_id} />
                          <Delete id={_id} click={delet} />
                      </Td>
                  </Tr>
              );
          })
      }
  </Table>
  }</>)


}

export default AllWorker;