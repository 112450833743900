import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1 } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditServiceGroup() {
  const [value, setValue] = useState({name:'', email:'', phone:'', address:'', file:{}});
  const [ img, setImg  ] = useState('');
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);
  const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function icon(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){

    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('email',value.email);
    formData.append('phone',value.phone);
    formData.append('address',value.address);
    formData.append('photo',value.file);

    fetch(`${url}/vendor/${id}`,{
      method:"PUT",
      body:  formData,
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });
  }

  useEffect(()=>{

    fetch(`${url}/vendor/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setValue({name: data.data[0].name, email: data.data[0].email, phone: data.data[0].phone, address: data.data[0].address});
          setImg(data.data[0].img);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

  },[id]);




  return (
    <Form1 title="Edit Vendor">
        <div className= " fixed top-16 right-2 ">
          <AlertContainer>
              {alert}
          </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter vendor name:" value={value.name} lavel="Vendor name:" />
        <Input1 onChange={set} name="email" placeholder="Enter vendor email:" value={value.email} lavel="Vendor email:" />
        <Input1 onChange={set} name="phone" placeholder="Enter vendor phone:" value={value.phone} lavel="Vendor phone:" />
        <Input1 onChange={set} name="address" placeholder="Enter vendor address:" value={value.address} lavel="Vendor address:" />
        <div className=' p-2 bg-white rounded-sm'><img className=' w-20' src={img} alt="" /></div>
        <Input1 onChange={(e)=>icon(e)} type="file" name="file" lavel="Upload a Photo." />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}
export default EditServiceGroup;