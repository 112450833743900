import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Option, Select, Textarea } from '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditService() {
  const [value, setValue] = useState({name:'', description:'', serviceGroupId:'', image:{}, banner:{}});
  const [serviceGroup, setServiceGroup] = useState([]);
  const [ img, setImg] = useState({image:'', banner:''});
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();
  const {id} = useParams();
   

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function getImage(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){

    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('description',value.description);
    formData.append('serviceGroupId',value.serviceGroupId);
    formData.append('image',value.image);
    formData.append('banner',value.banner);

      fetch(`${url}/service/${id}`,{
        method:"PUT",
        body:  formData,
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
      });
    }

  useEffect(()=>{

    fetch(`${url}/serviceGroup?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){;
            setServiceGroup(data.result.data)
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

    fetch(`${url}/service/${id}`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setValue({name: data.data.name, description: data.data.description,  serviceGroupId:data.data.serviceGroup._id});
        setImg({image: data.data.image.img, banner: data.data.banner.img});
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
  });

  },[id]);



  return (
    <Form1 title="Edit Service">
          <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter service name:" value={value.name} lavel="Service name:" />
        <Textarea onChange={set} name="description" placeholder="Enter service description:" value={value.description} lavel="Service description:" />
        <Select onChange={set}  name="serviceGroupId" value={value.serviceGroupId}  id="input4"  lavel="Select service group :">
                <Option value="" disable={true}>Select a service group</Option>
                    {
                        serviceGroup.map((data, index)=>{
                            return(
                                <Option key={index} value={data._id}>{data.name}</Option>
                            )
                        })
                    }
                    
                </Select>
        <div className=' p-2 mt-8 rounded-sm'><img className=' w-20' src={img.image} alt="" /></div>
        <Input1 onChange={(e)=>getImage(e)} type="file" name="image" lavel="Upload an image." />
        <div className=' p-2 mt-89+  rounded-sm'><img className=' w-20' src={img.banner} alt="" /></div>
        <Input1 onChange={(e)=>getImage(e)} type="file" name="banner" lavel="Upload a banner." />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}


export default EditService;