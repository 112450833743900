import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Textarea } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditServiceGroup() {
  const [value, setValue] = useState({name:'', description:'', file:{}});
  const [ img, setImg] = useState('');
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);
  const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function icon(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){

    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('description',value.description);
    formData.append('photo',value.file);

    fetch(`${url}/serviceGroup/${id}`,{
      method:"PUT",
      body:  formData,
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });
  }

  useEffect(()=>{

    fetch(`${url}/serviceGroup/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setValue({name: data.data[0].name, description: data.data[0].description});
          setImg(data.data[0].img);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
        }
    });

  },[id]);




  return (
    <Form1 title="Edit Service Group">
        <div className= " fixed top-16 right-2 ">
          <AlertContainer>
              {alert}
          </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter service group name:" value={value.name} lavel="Service group name:" />
        <Textarea onChange={set} name="description" placeholder="Enter service group description:" value={value.description} lavel="Service group description:" />
        <div className=' p-2 bg-white rounded-sm'><img className=' w-20' src={img} alt="" /></div>
        <Input1 onChange={(e)=>icon(e)} type="file" name="file" lavel="Upload an icon." />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}
export default EditServiceGroup;