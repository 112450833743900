import React, { useState, useEffect } from 'react';
import {  useParams } from 'react-router-dom';
import { Button1, Delete } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Select, Option } from  '../../components/Input';
import Table, { Tr, Td } from '../../components/Table';
import url from '../../url';

function ViewVendor(){
  const [vendorGroup, setVendorGroup] = useState([]);
  const [vendorGroupId, setVendorGroupId] = useState('');
  const [vendor, setVendor] = useState([]);
  const [vendorId, setVendorId] = useState('');
  const [vendorS, setVendorS] = useState([]);

  const { id } = useParams();


  function send(){
    fetch(`${url}/sale/vendor/${id}`,{
      method:"PUT",
      body: JSON.stringify({vendorId}),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        fetch(`${url}/sale/${id}`,{
          method:"GET",
          credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
          if(data.status === true){
            setVendorS(data.data.vendors)
          }
        });
      }
    });
  }

  useEffect(()=>{
    fetch(`${url}/vendorGroup?limit=0&page=1`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setVendorGroup(data.result.data)
      }
    });
  },[]);

  useEffect(()=>{
    fetch(`${url}/vendor/search?search=${vendorGroupId}&limit=0&page=1`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setVendor(data.result.data)
      }
    });
  },[vendorGroupId]);

  useEffect(()=>{
    fetch(`${url}/sale/${id}`,{
      method:"GET",
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setVendorS(data.data.vendors)
      }
    });
  },[id]);

  function remove(idd){
    fetch(`${url}/sale/vendor/${id}`,{
      method:"DELETE",
      body: JSON.stringify({vendorId:idd}),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        fetch(`${url}/sale/${id}`,{
          method:"GET",
          credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
          if(data.status === true){
            setVendorS(data.data.vendors)
          }
        });
      }
    });
  }


  return (<>
    <Form1 title="Add Vendor">
        <Select onChange={(e)=> setVendorGroupId(e.target.value)}  name="vendorGroupId" value={vendorGroupId}  id="input4"  lavel="Select vendor group :">
          <Option value="" disable={true}>Select a vendor group</Option>
          {
              vendorGroup.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Select onChange={(e)=> setVendorId(e.target.value)}  name="vendorId" value={vendorId}  id="input4"  lavel="Select vendor :">
          <Option value="" disable={true}>Select a vendor </Option>
          {
              vendor.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Button1 click={send} name="Save" />
    </Form1>

    <Table to="" name="" rowNames={["#","Name","Email","Phone","Address","Image", "Actions"]}  limit={0} data={{data:[],next:{page: 1, limit: 0},previous:{page: 0, limit: vendorS.length}}} width='w-full'>
      {
          vendorS.map(({_id, name, email, phone, address, img}, index)=>{
              return(
                  <Tr key={index}>
                      <Td>{1+index}</Td>
                      <Td>{name}</Td>
                      <Td width='w-40'>{email}</Td>
                      <Td width='w-40'>{phone}</Td>
                      <Td width='w-40'>{address}</Td>
                      <Td width='w-40 '><img className=' w-20 text-center block mx-auto' src={img} alt="" /></Td>
                      <Td>
                          <Delete id={_id} click={()=>remove(_id)} />
                      </Td>
                  </Tr>
              );
          })
        }
    </Table>

  </>)
}

export default ViewVendor;