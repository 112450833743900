import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Edit, View } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url,{ invUrl } from '../../url'

function AllCategory() {

const [sale, setSale] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit)
const [loader, setLoader] = useState(true);


useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/sale?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setSale(data.result);
            }
        });
    }

    return () => setSale({data:[],next:{},previous:{}});

},[page,src, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/sale/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setSale(data.result);
            }
        });
    
    }

    return () => setSale({data:[],next:{},previous:{}});
},[page, limit, src]);

    function search(e){
        setSrc(e.target.value);
    }

    function print(id){

        const w = window.open(`${invUrl}/sale/invoice/${id}`,"myWindow", 'fullscreen="yes"');

        setTimeout(()=>{
        w.close();
        }, 4000);

    }


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="" name="" rowNames={["#","Customer Name","Customer email","Customer phone","Customer address",  "Profit", "Total Price", "receivable", "received", "due","Reword", "discount", "Coupon", "Payment", "Note", "status", "date","Vendor","Actions"]} srcVal={src} srcFunc={search} data={sale} width='w-full'>
                {
                    sale.data.map(({_id, customer, profit, rProfit, totalPrice, receivable, received, due, rDue, reword, discount, coupon, payment, note, status, date}, index)=>{

                        const dt = new Date(date);

                        return(
                            <Tr key={index}>
                                <Td>{((sale.previous.page*sale.previous.limit)+1)+index}</Td>
                                <Td>{customer.name}</Td>
                                <Td>{customer.email}</Td>
                                <Td>{customer.phone}</Td>
                                <Td><div className=' w-36'>{customer.address}</div></Td>
                                <Td>{rProfit} ৳</Td>
                                <Td>{totalPrice} ৳</Td>
                                <Td>{receivable - discount} ৳</Td>
                                <Td>{received} ৳</Td>
                                <Td>{totalPrice - received - discount} ৳</Td>
                                <Td>{reword} ৳</Td>
                                <Td>{discount} ৳</Td>
                                <Td>{coupon}</Td>
                                <Td>{payment}</Td>
                                <Td>{note}</Td>
                                <Td>{status}</Td>
                                <Td>{dt.toLocaleString()}</Td>
                                <Td><View to={`/sale/${_id}/vendor`} /></Td>
                                <Td>
                                    <View to={"/sale/"+_id} />
                                    <Edit to={"/editSale/"+_id} />
                                    {/* <Delete id={_id} click={delet} /> */}
                                    {
                                        (status === "accepted" || status === "delivered")?<button onClick={()=>print(_id)} className=" text-orange-600 text-lg"><i className="fa-solid fa-print"></i></button>:<></>
                                    }
                                </Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
}</>)
}

export default AllCategory;