import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Select, Option } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';
import { useEffect } from 'react';

function AddVendor() {
  const [value, setValue] = useState({name:'', email:'', phone:'', address:'', vendorGroupId:'', file:{}});
  const [vendorGroup, setVendorGroup] = useState([]);
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function icon(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){


    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('email',value.email);
    formData.append('phone',value.phone);
    formData.append('address',value.address);
    formData.append('vendorGroupId',value.vendorGroupId);
    formData.append('photo',value.file);

    fetch(`${url}/vendor`,{
      method:"POST",
      body:  formData,
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
      }
    });
  }

  useEffect(()=>{
    fetch(`${url}/vendorGroup?limit=0&page=1`,{
      method:"GET",
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setVendorGroup(data.result.data)
      }
    });
  },[]);



  return (
    <Form1 title="Add Service Group">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter vendor name:" value={value.name} lavel="Vendor name:" />
        <Input1 onChange={set} name="email" placeholder="Enter vendor email:" value={value.email} lavel="Vendor email:" />
        <Input1 onChange={set} name="phone" placeholder="Enter vendor phone:" value={value.phone} lavel="Vendor phone:" />
        <Input1 onChange={set} name="address" placeholder="Enter vendor address:" value={value.address} lavel="Vendor address:" />
        <Select onChange={set}  name="vendorGroupId" value={value.vendorGroupId}  id="input4"  lavel="Select vendor group :">
          <Option value="" disable={true}>Select a vendor group</Option>
          {
              vendorGroup.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Input1 onChange={(e)=>icon(e)} type="file" name="file" lavel="Upload vendor photo." />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddVendor;